











































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component({
  name: 'BaseTicketSkeletonCard'
})
export default class BaseTicketSkeletonCard extends Vue {
  @Prop({ default: true }) readonly loading!: boolean;
}
